import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import image02 from "../../../../resources/static/iucatan04.jpg";
import image03 from "../../../../resources/static/iucatan06.jpg";
import image04 from "../../../../resources/static/iucatan08.jpg";
import image05 from "../../../../resources/static/iucatan03.jpg";
import image06 from "../../../../resources/static/iucatan17.jpg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React from "react";
import CustomSlider from "../../../custom/slider/CustomSlider";
import Footer from "../../../footer/Footer";

const Equoterapia = () => {
  const slides = [
    { image: image02 },
    { image: image03 },
    { image: image04 },
    { image: image05 },
    { image: image06 },
  ];

  return (
    <React.Fragment>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box component={"div"} sx={{ textAlign: "center", ml: "10px" }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                mt: "30px",
                backgroundImage:
                  "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Equitação para Equoterapia
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box
            component={"div"}
            sx={{ textAlign: "center", ml: "10px", mt: "30px", width: "75%" }}
          >
            <Paper about="descricao equitação básica" elevation={2}>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> A equitação para
                      equoterapia é uma terapia alternativa que utiliza cavalos
                      como intermediários para ajudar na recuperação de pessoas
                      com diversas condições, tais como problemas de saúde
                      mental, físicos e de desenvolvimento. O movimento natural
                      do cavalo é semelhante ao movimento humano, o que
                      significa que montar um cavalo pode melhorar a equilíbrio,
                      coordenação e força muscular. Além disso, a interação com
                      os cavalos pode ajudar a melhorar as habilidades sociais e
                      emocionais, aliviar o estresse e aumentar a autoestima. A
                      equoterapia é realizada por profissionais treinados,
                      incluindo fisioterapeutas, psicólogos e instrutores de
                      equitação, que trabalham em conjunto para desenvolver
                      programas de tratamento individualizados para cada
                      paciente. Em resumo, a equitação para equoterapia é uma
                      forma eficaz e inovadora de tratamento que oferece uma
                      variedade de benefícios para a saúde física e mental.
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
            <Divider
              sx={{ mb: "15px", mt: "15px", background: "black" }}
              variant="middle"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box component={"div"} sx={{ textAlign: "center", ml: "10px" }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                mt: "30px",
                backgroundImage:
                  "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Imagens
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box
            component={"div"}
            sx={{ textAlign: "center", ml: "10px", mt: "30px", width: "75%" }}
          >
            <Paper about="descricao equitação básica" elevation={2}>
              <CustomSlider slides={slides} />
            </Paper>
            <Divider
              sx={{ mb: "15px", mt: "15px", background: "black" }}
              variant="middle"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box component={"div"} sx={{ textAlign: "center", ml: "10px" }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                mt: "30px",
                backgroundImage:
                  "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Benefícios
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box
            component={"div"}
            sx={{
              textAlign: "center",
              ml: "10px",
              mt: "30px",
              width: "75%",
            }}
          >
            <Paper about="descricao equitação básica" elevation={2}>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Benefícios
                      físicos: Montar um cavalo pode ajudar a melhorar a
                      equilíbrio, coordenação e força muscular.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Benefícios
                      emocionais e sociais: A interação com os cavalos pode
                      ajudar a melhorar habilidades sociais e emocionais,
                      aliviar o estresse e aumentar a autoestima.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Equipe de
                      profissionais: A equoterapia é realizada por uma equipe de
                      profissionais treinados, incluindo fisioterapeutas,
                      psicólogos e instrutores de equitação.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Programas de
                      tratamento personalizados: A equipe de profissionais
                      trabalha em conjunto para desenvolver programas de
                      tratamento individualizados para cada paciente.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Sessões de
                      equitação e atividades terapêuticas: O tratamento pode
                      incluir sessões de equitação, atividades terapêuticas com
                      cavalos e terapia tradicional.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Ampla gama de
                      condições: A equoterapia é uma opção eficaz para pessoas
                      com uma ampla gama de condições, incluindo lesões na
                      coluna vertebral, traumatismo craneiano, esquizofrenia,
                      transtornos do espectro autista, problemas de
                      aprendizagem, dificuldades emocionais e muito mais.
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </React.Fragment>
  );
};

export default Equoterapia;
