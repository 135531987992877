import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CentroEquestre from "./Pages/CentroEquestre.jsx";
import EquipamentoPage from "./Pages/EquipamentoPage.jsx";
import EquitacaoAvancada from "./Pages/EquitacaoAvancada.jsx";
import EquitacaoBasica from "./Pages/EquitacaoBasica.jsx";
import EquitacaoEquoterapia from "./Pages/EquitacaoEquoterapia.jsx";
import EventosPage from "./Pages/EventosPage.jsx";
import ManejoPage from "./Pages/ManejoPage.jsx";
import TreinamentoEnduro from "./Pages/TreinamentoEnduro.jsx";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CentroEquestre />} />
        <Route path="/equitacao-basica" element={<EquitacaoBasica />} />
        <Route path="/equitacao-avancada" element={<EquitacaoAvancada />} />
        <Route path="/treinamento-enduro" element={<TreinamentoEnduro />} />
        <Route path="/equitacao-equoterapia" element={<EquitacaoEquoterapia />} />
        <Route path="/equipamentos" element={<EquipamentoPage />} />
        <Route path="/manejo" element={<ManejoPage />} />
        <Route path="/eventos" element={<EventosPage />} />
      </Routes>
    </Router>
  );
};

export default App;
  