import React from "react";
import Header from "../components/header/Header";
import Avancada from "../components/know-more/equitacao/avancada/Avancada";

const EquitacaoAvancada = () => {
  const titile = "Centro Equestre Iucatan";

  return (
    <React.Fragment>
      <Header title={titile} />
      <Avancada />
    </React.Fragment>
  );
};

export default EquitacaoAvancada;
