import React from "react";
import Header from "../components/header/Header";
import Eventos from "../components/know-more/eventos/Eventos";

const EventosPage = () => {
  const titile = "Centro Equestre Iucatan";
  return (
    <React.Fragment>
      <Header title={titile} />
      <Eventos />
    </React.Fragment>
  );
};

export default EventosPage;
