import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey, red, green } from "@mui/material/colors";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const PlusContentPlan = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: red["900"],
      },
      secondary: {
        main: grey["50"],
      },
      tertiary: {
        main: green["A700"],
      },
    },
  });

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Box component="div" sx={{ mt: "20px" }}>
          <Typography>
            <Typography component="li" variant="h6" color="black" align="left">
              Parcelamento em até 6 vezes
              <CheckRoundedIcon
                sx={{ ml: "15px", color: theme.palette.tertiary.main }}
              />
            </Typography>
            <Divider variant="middle" sx={{ mt: "5px", mb: "5px" }} />
            <Typography component="li" variant="h6" color="black" align="left">
              Direito a reposição com justificativa{" "}
              <CheckRoundedIcon
                sx={{ ml: "15px", color: theme.palette.tertiary.main }}
              />
            </Typography>
            <Divider variant="middle" sx={{ mt: "5px", mb: "5px" }} />
            <Typography component="li" variant="h6" color="black" align="left">
              Quatro aulas por mês{" "}
              <CheckRoundedIcon
                sx={{ ml: "15px", color: theme.palette.tertiary.main }}
              />
            </Typography>
            <Divider variant="middle" sx={{ mt: "5px", mb: "5px" }} />
            <Typography component="li" variant="h6" color="black" align="left">
              Direito a levar dois amigos por mês{" "}
              <CheckRoundedIcon
                sx={{ ml: "15px", color: theme.palette.tertiary.main }}
              />
            </Typography>
            <Divider variant="middle" sx={{ mt: "5px", mb: "5px" }} />
            <Typography component="li" variant="h6" color="black" align="left">
              2 aulas adicionais (Válidas para uso até 2 meses após encerramento
              do plano)
              <CheckRoundedIcon
                sx={{ ml: "15px", color: theme.palette.tertiary.main }}
              />
            </Typography>
            <Divider variant="middle" sx={{ mt: "5px", mb: "5px" }} />
            <Typography component="li" variant="h6" color="black" align="left">
              Desconto na contratação de eventos{" "}
              <CheckRoundedIcon
                sx={{ ml: "15px", color: theme.palette.tertiary.main }}
              />
            </Typography>
            <Divider variant="middle" sx={{ mt: "5px", mb: "5px" }} />
            <Typography component="li" variant="h6" color="black" align="left">
              Desconto na compra de ingressos para palestras, clínicas, cursos e
              provas de enduro{" "}
              <CloseRoundedIcon
                sx={{ ml: "15px", color: theme.palette.primary.main }}
              />
            </Typography>
            <Grid container spacing={2} padding="10px">
              <Grid item xs={12} container sm>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xs
                >
                  <Grid item>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography variant="h6">De:</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography variant="h5">
                        R$599,90
                        <Typography variant="h6" display="inline">
                          /mês
                        </Typography>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xs
                >
                  <Grid item>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          background:
                            "-webkit-linear-gradient( rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                          backgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      >
                        Por:
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          background:
                            "-webkit-linear-gradient( rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                          backgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      >
                        R$479
                      </Typography>
                      <Typography
                        variant="h6"
                        display="inline"
                        sx={{
                          background:
                            "-webkit-linear-gradient( rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                          backgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      >
                        /mês
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider variant="middle" sx={{ mt: "5px", mb: "5px" }} />
            <Typography variant="h7" color="black">
              Pagamento Anual - R$ 2.880
            </Typography>
          </Typography>
        </Box>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default PlusContentPlan;
