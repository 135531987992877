import React from "react"
import Header from "../components/header/Header.jsx"
import Manejo from "../components/know-more/equitacao/manejo/Manejo.jsx";

const ManejoPage = () => {
    const titile = "Centro Equestre Iucatan";

    return <React.Fragment>
        <Header title={titile}/>
        <Manejo />
    </React.Fragment>
}

export default ManejoPage