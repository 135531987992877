import React from "react";
import Header from "../components/header/Header";
import Equipamentos from "../components/know-more/equitacao/equipamentos/Equipamentos";

const EquipamentoPage = () => {
  const titile = "Centro Equestre Iucatan";
  return (
    <React.Fragment>
      <Header title={titile} />
      <Equipamentos />
    </React.Fragment>
  );
};

export default EquipamentoPage;
