import React from "react";
import { Button, Collapse, Container, Grid } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Info from "../../resources/info/Info";
import CustomItem from "../custom/Item/CustomItem";

const Certificates = ({ open, setOpen }) => {
  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        xs={12}
      >
        <Grid item xs={12} sx={{ textAlign: "center", ml: "10px", mt: "20px" }}>
          <Button
            sx={{
              backgroundImage:
                "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
              backgroundClip: "text",
              color: "transparent",
            }}
            onClick={() => setOpen(!open)}
          >
            Certificados {open ? <ExpandLess /> : <ExpandMore />}
          </Button>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container spacing={2} padding="10px">
              <Grid item xs={12} container sm>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xs
                  padding="10px"
                >
                  <Grid item>
                    <CustomItem
                      type={"text"}
                      children={Info().about.certificates.first}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xs
                  padding="10px"
                >
                  <Grid item>
                    <CustomItem
                      type={"text"}
                      children={Info().about.certificates.second}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} padding="10px">
              <Grid item xs={12} container sm>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xs
                  padding="10px"
                >
                  <Grid item>
                    <CustomItem
                      type={"text"}
                      children={Info().about.certificates.third}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xs
                  padding="10px"
                >
                  <Grid item>
                    <CustomItem
                      type={"text"}
                      children={Info().about.certificates.fourth}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} padding="10px">
              <Grid item xs={12} container sm>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xs
                  padding="10px"
                >
                  <Grid item>
                    <CustomItem
                      type={"text"}
                      children={Info().about.certificates.fifth}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xs
                  padding="10px"
                >
                  <Grid item>
                    <CustomItem
                      type={"text"}
                      children={Info().about.certificates.sixth}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} padding="10px">
              <Grid item xs={12} container sm>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xs
                  padding="10px"
                >
                  <Grid item>
                    <CustomItem
                      type={"text"}
                      children={Info().about.certificates.seventh}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xs
                  padding="10px"
                >
                  <Grid item>
                    <CustomItem
                      type={"text"}
                      children={Info().about.certificates.eighth}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Certificates;
