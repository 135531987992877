import React from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
// import image02 from "../../../../resources/static/iucatan04.jpg";
// import image03 from "../../../../resources/static/iucatan06.jpg";
// import image04 from "../../../../resources/static/iucatan08.jpg";
// import image05 from "../../../../resources/static/iucatan03.jpg";
// import image06 from "../../../../resources/static/iucatan17.jpg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import CustomSlider from "../../../custom/slider/CustomSlider";
import Footer from "../../../footer/Footer";

const Equipamentos = () => {
  //   const slides = [
  //     { image: image02 },
  //     { image: image03 },
  //     { image: image04 },
  //     { image: image05 },
  //     { image: image06 },
  //   ];

  return (
    <React.Fragment>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box component={"div"} sx={{ textAlign: "center", ml: "10px" }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                mt: "30px",
                backgroundImage:
                  "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Equipamentos
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box
            component={"div"}
            sx={{ textAlign: "center", ml: "10px", mt: "30px", width: "75%" }}
          >
            <Paper about="descricao equitação básica" elevation={2}>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Os equipamentos
                      de equitação são essenciais para garantir a segurança e o
                      bem-estar do cavalo e do cavaleiro durante a montaria.
                      Alguns dos equipamentos mais comuns incluem: As selas são
                      a ligação entre o cavaleiro e o cavalo. Existem vários
                      tipos de selas, como selas inglesas, westerns, de saltos,
                      de adestramento, entre outras, cada uma adequada a
                      diferentes estilos de equitação. As rédeas são usadas para
                      guiar e controlar o cavalo. As rédeas podem ser feitas de
                      couro, nylon ou outros materiais, e podem ser fixas ou
                      ajustáveis. Os estribos são usados para dar apoio aos pés
                      do cavaleiro durante a montaria. Eles podem ser feitos de
                      metal ou plástico, e alguns modelos incluem barras de
                      segurança para evitar que o cavaleiro escorregue. As
                      proteções para o cavaleiro incluem capacetes, coletes de
                      proteção, luvas e botas. As proteções para o cavalo
                      incluem protetores de pernas, cobertores e peitorais.
                      Alguns dos acessórios comuns para treinamento incluem
                      longes, cavilhas, chicotes, bandagens, etc. Eles são
                      usados para ajudar no condicionamento e treinamento do
                      cavalo. Alguns acessórios de performance incluem arreios
                      para mostrar, penachos, enfeites, etc. Eles são usados
                      para melhorar a aparência e desempenho do cavalo em
                      competições e demonstrações. Em geral, é importante
                      escolher equipamentos de qualidade e apropriados para as
                      necessidades do cavalo e do cavaleiro. Além disso, é
                      importante verificar regularmente a condição dos
                      equipamentos e substituí-los se necessário para garantir a
                      segurança durante a equitação.
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
            <Divider
              sx={{ mb: "15px", mt: "15px", background: "black" }}
              variant="middle"
            />
          </Box>
        </Grid>
      </Grid>
      {/* <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box component={"div"} sx={{ textAlign: "center", ml: "10px" }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                mt: "30px",
                backgroundImage:
                  "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Imagens
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box
            component={"div"}
            sx={{ textAlign: "center", ml: "10px", mt: "30px", width: "75%" }}
          >
            <Paper about="descricao equitação básica" elevation={2}>
              <CustomSlider slides={slides} />
            </Paper>
          </Box>
        </Grid>
      </Grid> */}
      <Footer />
    </React.Fragment>
  );
};

export default Equipamentos;
