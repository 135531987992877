import React from "react";
import Header from "../components/header/Header.jsx";
import Basica from "../components/know-more/equitacao/basica/Basica.jsx";

const EquitacaoBasica = () => {
  const titile = "Centro Equestre Iucatan";

  return (
    <React.Fragment>
      <Header title={titile} />
      <Basica />
    </React.Fragment>
  );
};

export default EquitacaoBasica;
