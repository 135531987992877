import React from "react";
import { Carousel } from "react-bootstrap";
import { useState } from "react";
import { Box } from "@mui/material";

/**
 * TODO: Images must be 1980x1200.
 */

/**
 * @author Tiago Dias
 * @version 1.0
 * @param {slides} -> Object for creation of caroussel of images
 * @returns
 */
const CustomSlider = ({ slides }) => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} interval={3000}>
      {slides.map((objetoSlide) => {
        return (
          <Carousel.Item>
            <Box
              component={"img"}
              sx={{ width: "100%" }}
              src={objetoSlide.image}
            />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default CustomSlider;
