import React from "react";
import CustomItem from "../../custom/Item/CustomItem.jsx";
import PremiunContentPlan from "./PremiunContentPlan.jsx";
import { Grid, Typography } from "@mui/material";

const PremiunPlan = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      xs={12}
    >
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography
          variant="h2"
          sx={{
            background:
              "-webkit-linear-gradient( rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Premium
        </Typography>
        <CustomItem type={"text"} children={<PremiunContentPlan />} />
      </Grid>
    </Grid>
  );
};

export default PremiunPlan;
