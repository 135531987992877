const Info = () => {
  const info = {
    about: {
      description:
        "Marco Antonio teve seu primeiro contato com o cavalo em 1974, e rapidamente desenvolveu habilidades tanto na montaria quanto no trato com os animais. Em 1987, ele se tornou um renomado instrutor de equitação na Avalon. Ao longo dos anos, ele se especializou em equitação terapêutica e enduro, participando de cursos e competições. Ele possui um currículo notável, incluindo o curso de doma racional de Monty Roberts e uma extensa experiência como instrutor de equitação terapêutica para pessoas com deficiências. Em homenagem a um cavalo especial, ele fundou o Centro Equestre Iucatan junto com sua esposa, tornando-o um dos maiores e mais bem-sucedidos de Brasília e do Brasil, vencendo inúmeras competições de equipe e individuais.",
      descriptionWhatWeDo:
        "O Centro Equestre Iucatan é uma escola completa, que não se limita a ensinar equitação, mas sim abrange todos os aspectos do manejo do cavalo. O objetivo é capacitar os alunos a compreender e lidar com os cavalos de forma completa, desde entender os sinais de comportamento até manipular os diversos equipamentos de equitação. Além disso, o centro também se dedica à equoterapia, ajudando os alunos a progredir em suas áreas de interesse. É também um ambiente acolhedor para famílias, oferecendo oportunidades para passarem tempo juntos, fortalecer laços e desfrutarem da natureza.",
      certificates: {
        first: "I Congresso De Equoterapia - Novembro de 1999 em Brasília - DF",
        second:
          "I Curso Básico de Equoterapia - Abril de 1997 em Brasília - DF",
        third:
          "XII International Congress of Therapeutic Riding - August 2000 in Brasília - DF",
        fourth:
          "I Curso Básico de Equitação para Equoterapia - Maio / Junho de 2000 em Brasília - DF",
        fifth: "I Curso de Manejo em Equinos - Março de 2008 em Brasília - DF",
        sixth:
          "I Curso de Evolução em Nutrição de Equinos - Julho de 2000 em Brasília - DF",
        seventh: "I Clínica Equina - Março de 2000 em Brasília - DF",
        eighth:
          "I Treinamento de Casquamento de Equíneos - Maio de 2007 em Brasília - DF",
      },
      whatWeWork: {
        equitacaoBasica:
          "Equitação básica inclui técnicas como técnicas de equilíbrio, comunicação e treinamento para cavalgar com segurança.",
        equitacaoAvançada:
          "Equitação avançada inclui técnicas como adestramento, saltos, voltas e manobras complexas. Requer habilidade e experiência.",
        treinamentoParaEnduro:
          "O treinamento para enduro inclui a preparação física e técnica do cavalo e do cavaleiro para competições de longa distância, exigindo resistência, velocidade e habilidades de navegação.",
        equitacaoParaEquoterapia:
          "Equoterapia é uma terapia que utiliza cavalos para tratar condições físicas e mentais. A equitação é usada como uma ferramenta terapêutica para melhorar a força, flexibilidade e autoestima.",
        equoterapia:
          "Equoterapia é uma terapia que utiliza cavalos como ferramenta para tratar condições físicas e mentais, incluindo autismo, paralisia cerebral e transtorno de estresse pós-traumático.",
        tecnicasDeManejoDeCavalo:
          "As técnicas de manejo do cavalo incluem métodos de treinamento, condicionamento físico, comunicação com o cavalo e garantia de segurança durante o trabalho com o animal.",
        conhecimentoDosEquipamentosUsadosNoCavalo:
          "Equipamentos de equitação incluem selas, rédeas, estribos, proteções e acessórios para treinamento e performance. Adaptados às necessidades do cavalo e do cavaleiro.",
      },
    },
  };

  return info;
};

export default Info;
