import React from "react";
import Header from "../components/header/Header";
import Equoterapia from "../components/know-more/equitacao/equoterapia/Equoterapia";

const EquitacaoEquoterapia = () => {
  const titile = "Centro Equestre Iucatan";

  return (
    <React.Fragment>
      <Header title={titile} />
      <Equoterapia />
    </React.Fragment>
  );
};

export default EquitacaoEquoterapia;
