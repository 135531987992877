import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React from "react";
import CustomSlider from "../../../custom/slider/CustomSlider";
import image01 from "../../../../resources/static/iucatan04.jpg";
import image02 from "../../../../resources/static/iucatan06.jpg";
import image03 from "../../../../resources/static/iucatan03.jpg";
import image04 from "../../../../resources/static/iucatan10.jpg";
import Footer from "../../../footer/Footer";

const Basica = () => {
  const slides = [
    { image: image01 },
    { image: image02 },
    { image: image03 },
    { image: image04 },
  ];

  return (
    <React.Fragment>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box component={"div"} sx={{ textAlign: "center", ml: "10px" }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                mt: "30px",
                backgroundImage:
                  "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Equitação Básica
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box
            component={"div"}
            sx={{ textAlign: "center", ml: "10px", mt: "30px", width: "75%" }}
          >
            <Paper about="descricao equitação básica" elevation={2}>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Equitação é uma
                      atividade recreativa e esportiva que envolve a montaria de
                      cavalos. A equitação básica inclui noções sobre a postura
                      correta do cavaleiro, como sentar-se de forma equilibrada
                      e alinhada com o movimento do cavalo. Também envolve o uso
                      de comandos e ajudas para guiar o cavalo, como o uso dos
                      joelhos, peso do corpo e rédeas. A equitação básica também
                      inclui o conhecimento das necessidades básicas do cavalo,
                      incluindo cuidados com a saúde, nutrição e segurança. A
                      prática da equitação pode ser uma forma divertida e
                      desafiadora de se conectar com os cavalos e desenvolver
                      habilidades de equilíbrio, coordenação e confiança.
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
            <Divider
              sx={{ mb: "15px", mt: "15px", background: "black" }}
              variant="middle"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box component={"div"} sx={{ textAlign: "center", ml: "10px" }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                mt: "30px",
                backgroundImage:
                  "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Imagens
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box
            component={"div"}
            sx={{ textAlign: "center", ml: "10px", mt: "30px", width: "75%" }}
          >
            <Paper about="descricao equitação básica" elevation={2}>
              <CustomSlider slides={slides} />
            </Paper>
            <Divider
              sx={{ mb: "15px", mt: "15px", background: "black" }}
              variant="middle"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box component={"div"} sx={{ textAlign: "center", ml: "10px" }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                mt: "30px",
                backgroundImage:
                  "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Benefícios
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box
            component={"div"}
            sx={{
              textAlign: "center",
              ml: "10px",
              mt: "30px",
              width: "75%",
            }}
          >
            <Paper about="descricao equitação básica" elevation={2}>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Exercício físico:
                      Montar a cavalo pode ser um exercício físico moderado que
                      trabalha músculos do corpo inteiro, incluindo pernas,
                      quadris, abdômen e costas.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Redução do
                      estresse: A atividade ao ar livre e o contato com os
                      cavalos podem ajudar a reduzir o estresse e a ansiedade.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Melhora do
                      equilíbrio e coordenação: Montar um cavalo envolve
                      equilibrar-se com o movimento do animal, o que pode
                      melhorar o equilíbrio e a coordenação do cavaleiro.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Desenvolvimento
                      da confiança: A equitação pode ajudar a aumentar a
                      confiança ao desafiar o cavaleiro a superar obstáculos e
                      tentar novas habilidades.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Conexão com a
                      natureza: A equitação pode oferecer uma oportunidade de
                      conexão com a natureza, bem como a chance de apreciar
                      paisagens e vistas inesquecíveis.
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
            <Divider
              sx={{ mb: "15px", mt: "15px", background: "black" }}
              variant="middle"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box component={"div"} sx={{ textAlign: "center", ml: "10px" }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                mt: "30px",
                backgroundImage:
                  "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Níveis
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box
            component={"div"}
            sx={{
              textAlign: "center",
              ml: "10px",
              mt: "30px",
              width: "75%",
            }}
          >
            <Paper about="descricao equitação básica" elevation={2}>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Iniciante:
                      Equitação iniciante é a introdução básica ao cavalo e ao
                      cavaleiro, incluindo como montar, dirigir e cuidar do
                      cavalo. Aprende-se posição e equilíbrio.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Intermediário:
                      Equitação envolve técnicas avançadas como galopar, saltar
                      obstáculos e treinar o cavalo para responder a comandos
                      complexos. Há maior ênfase na habilidade do cavaleiro.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Avançado: Nível
                      avançado de equitação é quando o cavaleiro e o cavalo
                      atuam como uma equipe sincronizada, realizando movimentos
                      complexos e competindo em provas de equitação a níveis
                      elevados. Habilidade e confiança são chaves.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Avançado -
                      Enduro: Nível avançado de enduro equestre é uma competição
                      longa e desafiadora que testa a habilidade do cavaleiro e
                      a resistência do cavalo em percursos de cross-country e
                      obstáculos. Requer técnica, preparo físico e mental.
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </React.Fragment>
  );
};

export default Basica;
