import React, { useState } from "react";
import { Divider, Grid, Typography, Container } from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Marcos from "../../resources/static/iucatan06.jpg";
import CustomItem from "../custom/Item/CustomItem.jsx";
import CustomComponentImage from "../custom/images/CustomComponentImage.js";
import Certificates from "../certificates/Certificates";
import Work from "../work/Work";

const About = (props) => {
  const [open, setOpen] = useState(false);
  const [openWork, setOpenWork] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: red["900"],
      },
      secondary: {
        main: grey["50"],
      },
    },
  });

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        xs
      >
        <Grid item>
          <ThemeProvider theme={theme}>
            <Typography
              variant="h4"
              sx={{
                mb: "30px",
                backgroundImage:
                  "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Professor Marco
            </Typography>
          </ThemeProvider>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        xs
      >
        <Grid item>
          <CustomItem type={"img"}>
            <CustomComponentImage Marcos={Marcos} />
          </CustomItem>
        </Grid>
        <Grid item>
          <CustomItem type={"text"} children={props.descricao} />
          <Certificates open={open} setOpen={setOpen} />
        </Grid>
      </Grid>
      <Divider
        sx={{ mb: "15px", mt: "15px", background: "black" }}
        variant="middle"
      />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        xs
      >
        <Grid item>
          <ThemeProvider theme={theme}>
            <Typography
              variant="h4"
              sx={{
                mb: "30px",
                backgroundImage:
                  "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Oque Fazemos ?
            </Typography>
          </ThemeProvider>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        xs
      >
        <Grid item>
          <CustomItem type={"text"} children={props.descricaoOqueFazemos} />
        </Grid>
        <Work openWork={openWork} setOpenWork={setOpenWork} />
      </Grid>
    </Container>
  );
};

export default About;
