import React from "react";
import { Paper } from "@mui/material";

const CustomItem = ({ type, children }) => {
  return (
    <Paper
      elevation={2}
      sx={{
        backgroundColor:
          type === "img"
            ? "-webkit-linear-gradient( rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))"
            : "#fff",
        textAlign: "center",
        color: "#212121",
        padding: "10px",
        typography: "h6",
      }}
    >
      {children}
    </Paper>
  );
};

export default CustomItem;
