import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey, red, green } from "@mui/material/colors";
import BasicPlan from "./basic/BasicPlan.jsx";
import PlusPlan from "./plus/PlusPlan.jsx";
import PremiunPlan from "./premiun/PremiunPlan.jsx";

const Plans = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: red["900"],
      },
      secondary: {
        main: grey["50"],
      },
      tertiary: {
        main: green["A700"],
      },
    },
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={theme}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box>
            <Typography
              variant="h2"
              sx={{
                mb: "30px",
                background:
                  "-webkit-linear-gradient( rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Planos
            </Typography>
          </Box>
        </Grid>
        <Grid container spacing={3} padding="30px">
          <Grid item xs={12} container sm>
            <Grid item xs container direction="column" spacing={1}>
              <Grid item>
                <PremiunPlan theme={theme} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container sm>
            <Grid item xs container direction="column" spacing={1}>
              <Grid item>
                <PlusPlan theme={theme} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container sm>
            <Grid item xs container direction="column" spacing={1}>
              <Grid item>
                <BasicPlan theme={theme} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Box>
  );
};

export default Plans;
