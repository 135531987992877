import React from "react";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CustomSlider from "../../../custom/slider/CustomSlider";
import image01 from "../../../../resources/static/iucatan02.jpg";
import image02 from "../../../../resources/static/iucatan11.JPG";
import image03 from "../../../../resources/static/iucatan12.JPG";
import Footer from "../../../footer/Footer";

const Enduro = () => {
  const slides = [{ image: image01 }, { image: image02 }, { image: image03 }];
  return (
    <React.Fragment>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box component={"div"} sx={{ textAlign: "center", ml: "10px" }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                mt: "30px",
                backgroundImage:
                  "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Treinamento de Enduro
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box
            component={"div"}
            sx={{ textAlign: "center", ml: "10px", mt: "30px", width: "75%" }}
          >
            <Paper about="descricao equitação básica" elevation={2}>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> O treinamento
                      para enduro equestre é crucial para o sucesso em
                      competições desse tipo. Enduro é uma prova longa e
                      desafiadora que testa a habilidade do cavaleiro e a
                      resistência do cavalo em percursos de cross-country e
                      obstáculos. Portanto, é importante que ambos estejam
                      devidamente preparados. O treinamento para enduro começa
                      com a condição física do cavalo. Ele deve ser treinado
                      para correr longas distâncias e enfrentar obstáculos,
                      incluindo subidas e descidas íngremes, água, etc. É
                      importante que o cavalo esteja em boa forma física e
                      mental para enfrentar as exigências da prova. O cavaleiro
                      também precisa se preparar fisicamente, pois a prova pode
                      durar várias horas e exigir muito esforço físico. Além
                      disso, o cavaleiro precisa desenvolver habilidades
                      técnicas para lidar com obstáculos e ter uma boa postura
                      para ajudar a manter o equilíbrio durante a prova. Treinar
                      juntos é uma parte importante do treinamento para enduro.
                      O cavaleiro e o cavalo precisam desenvolver uma conexão
                      forte e confiança um no outro para realizar a prova com
                      sucesso. É importante que o cavaleiro treine com o cavalo
                      em diferentes ambientes para ajudá-los a se adaptar a
                      situações diferentes e desafiadoras. Além disso, é
                      importante que o cavaleiro esteja familiarizado com as
                      regras e as exigências da prova de enduro. É importante
                      seguir as regras de segurança e preparar o cavalo e o
                      equipamento adequadamente para a prova. Em resumo, o
                      treinamento para enduro equestre é uma combinação de
                      condicionamento físico, habilidades técnicas e treinamento
                      conjunto. É importante que o cavaleiro e o cavalo estejam
                      devidamente preparados para enfrentar as exigências da
                      prova e ter sucesso na competição.
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
            <Divider
              sx={{ mb: "15px", mt: "15px", background: "black" }}
              variant="middle"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box component={"div"} sx={{ textAlign: "center", ml: "10px" }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                mt: "30px",
                backgroundImage:
                  "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Imagens
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box
            component={"div"}
            sx={{ textAlign: "center", ml: "10px", mt: "30px", width: "75%" }}
          >
            <Paper about="descricao equitação básica" elevation={2}>
              <CustomSlider slides={slides} />
            </Paper>
            <Divider
              sx={{ mb: "15px", mt: "15px", background: "black" }}
              variant="middle"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box component={"div"} sx={{ textAlign: "center", ml: "10px" }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                mt: "30px",
                backgroundImage:
                  "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Benefícios
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box
            component={"div"}
            sx={{
              textAlign: "center",
              ml: "10px",
              mt: "30px",
              width: "75%",
            }}
          >
            <Paper about="descricao equitação básica" elevation={2}>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Desenvolvimento
                      de habilidades de equitação: A prática de enduro equestre
                      ajuda a desenvolver habilidades de equitação, como
                      equilíbrio, coordenação, confiança e comunicação com o
                      cavalo.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Aperfeiçoamento
                      da condição física: Enduro equestre é uma atividade física
                      desafiadora que exige boa forma física tanto do cavalo
                      quanto do cavaleiro. Isso pode ajudar a melhorar a saúde
                      geral, aumentar a resistência e fortalecer músculos e
                      ossos.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Conexão com a
                      natureza: A prática de enduro equestre geralmente acontece
                      ao ar livre, permitindo que cavaleiros e cavalos se
                      conectem com a natureza e desfrutem de paisagens
                      espetaculares
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Experiência de
                      equipe: Enduro equestre é uma competição em equipe ou
                      individual, o que permite aos cavaleiros trabalharem
                      juntos em busca de um objetivo comum. Isso pode ajudar a
                      desenvolver habilidades de trabalho em equipe e amizade.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Desafio pessoal:
                      Enduro equestre é uma atividade desafiadora que exige
                      dedicação e determinação para se sair bem. Conquistar
                      metas e superar obstáculos pode ser uma experiência
                      gratificante e estimulante.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Lazer e diversão:
                      Além de ser uma atividade desafiadora, enduro equestre é
                      uma forma divertida de passar o tempo ao ar livre e
                      desfrutar da companhia de cavalos e outros cavaleiros.
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </React.Fragment>
  );
};

export default Enduro;
