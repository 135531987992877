import React from "react";
import { Box, Divider } from "@mui/material";
import About from "../components/about/about.jsx";
import Footer from "../components/footer/Footer.jsx";
import Header from "../components/header/Header.jsx";
import Plans from "../components/Plans/Plans.jsx";
import image02 from "../resources/static/iucatan04.jpg";
import image03 from "../resources/static/iucatan06.jpg";
import image04 from "../resources/static/iucatan08.jpg";
import image05 from "../resources/static/iucatan03.jpg";
import image06 from "../resources/static/iucatan17.jpg";
import CustomSlider from "../components/custom/slider/CustomSlider.jsx";
import Info from "../resources/info/Info.js";

/* TODO:
    - [ done ] Implementar seção de descrição "Quem somós"
    - [ done ] Implementar seção "Com oque trabalhamos"
    - [  ] Implementar seção de vídeos
  */

const CentroEquestre = () => {
  const titile = "Centro Equestre Iucatan";

  const slides = [
    { image: image02 },
    { image: image03 },
    { image: image04 },
    { image: image05 },
    { image: image06 },
  ];

  return (
    <Box component="div">
      <Header title={titile} />
      <CustomSlider slides={slides} />
      <Divider
        sx={{ mb: "15px", mt: "15px", background: "black" }}
        variant="middle"
      />
      <About
        descricao={Info().about.description}
        descricaoOqueFazemos={Info().about.descriptionWhatWeDo}
      />
      <Divider
        sx={{ mb: "15px", mt: "15px", background: "black" }}
        variant="middle"
      />
      <Plans />
      <Footer />
    </Box>
  );
};

export default CentroEquestre;
