import { React, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InstagramIcon from "@mui/icons-material/Instagram";
import EventIcon from "@mui/icons-material/Event";
import MenuIcon from "@mui/icons-material/Menu";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { Button, Link, Menu, MenuItem, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#fc0214cc",
      },
      secondary: {
        main: grey["50"],
      },
    },
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={theme}>
        <AppBar
          position="static"
          sx={{
            background:
              "-webkit-linear-gradient( rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
          }}
        >
          <Toolbar>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              color="inherit"
              onClick={handleClick}
            >
              <MenuIcon />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  navigate("/");
                }}
              >
                Home
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/eventos");
                }}
              >
                Eventos
              </MenuItem>
            </Menu>
            <Typography
              sx={{
                flexGrow: 1,
              }}
              color="inherit"
              variant="h6"
              component="div"
            >
              <Link href="/" underline="none" color="inherit">
                {props.title}
              </Link>
            </Typography>
            <Divider />
            <Tooltip title="Eventos">
              <Link
                sx={{
                  mr: 2,
                }}
                href="/eventos"
                underline="none"
                color="inherit"
              >
                <EventIcon />
              </Link>
            </Tooltip>
            <Tooltip title="Localização">
              <Link
                sx={{
                  mr: 2,
                }}
                href="https://www.google.com/maps/place/Centro+Equestre+Iucatan/@-15.7524039,-47.6553618,15z/data=!4m5!3m4!1s0x0:0x51e142a2051d4f94!8m2!3d-15.7524039!4d-47.6553618"
                underline="none"
                color="inherit"
              >
                <LocationOnIcon />
              </Link>
            </Tooltip>
            <Tooltip title="Acesse nosso instagram">
              <IconButton
                color="inherit"
                rel="noopener noreferrer"
                href="https://www.instagram.com/ranchoiucatan/"
                sx={{
                  mr: 2,
                }}
              >
                <InstagramIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Entre em contato">
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                target="_top"
                color="inherit"
                rel="noopener noreferrer"
                href="https://api.whatsapp.com/send?phone=+55061992093249&text=Estou vindo do site e gostaria de saber mais sobre ! "
                sx={{
                  mr: 2,
                }}
              >
                <WhatsAppIcon sx={{}} />
              </IconButton>
            </Tooltip>
            <Divider />
            <Tooltip title="Envie um e-mail">
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                target="_top"
                color="inherit"
                rel="noopener noreferrer"
                href="mailto:braga1358@gmail.com"
                sx={{
                  mr: 2,
                }}
              >
                <EmailIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </Box>
  );
};

export default Header;
