import React from "react";
import CustomItem from "../../custom/Item/CustomItem.jsx";
import PlusContentPlan from "./PlusContentPlan.jsx";
import { Grid, Typography } from "@mui/material";

const PlusPlan = ({ theme }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      xs={12}
    >
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography
          variant="h2"
          sx={{
            background:
              "-webkit-linear-gradient( rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Plus
        </Typography>
        <CustomItem
          type={"text"}
          children={<PlusContentPlan theme={theme} />}
        />
      </Grid>
    </Grid>
  );
};

export default PlusPlan;
