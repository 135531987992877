import React from "react";

import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Footer from "../../../footer/Footer";

const Manejo = () => {
  return (
    <React.Fragment>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box component={"div"} sx={{ textAlign: "center", ml: "10px" }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                mt: "30px",
                backgroundImage:
                  "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Manejo
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box
            component={"div"}
            sx={{ textAlign: "center", ml: "10px", mt: "30px", width: "75%" }}
          >
            <Paper about="descricao equitação básica" elevation={2}>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> O ensino do
                      manejo de cavalos é fundamental para qualquer pessoa que
                      deseje trabalhar com esses animais ou ter uma interação
                      segura e saudável com eles. O aprendizado envolve diversos
                      aspectos, como alimentação, higiene, saúde, comportamento
                      e técnicas de equitação. Os alunos devem ser ensinados
                      sobre os diferentes tipos de raças de cavalos e suas
                      características, bem como como identificar sinais de
                      doença ou desconforto nos animais. Também é importante
                      ensinar sobre as ferramentas e equipamentos necessários
                      para cuidar dos cavalos, como escovas, selas e bridões.
                      Além disso, o manejo de cavalos envolve o desenvolvimento
                      de habilidades práticas, como escovar, limpar cascos e
                      prender o animal corretamente. Os alunos também precisam
                      aprender como montar e desmontar, como se comunicar com o
                      cavalo e como lidar com situações de emergência. O ensino
                      do manejo de cavalos deve ser conduzido por profissionais
                      experientes e qualificados, que possam fornecer aos alunos
                      uma educação completa e segura. As práticas de treinamento
                      devem ser realizadas em instalações adequadas, com a
                      supervisão adequada e seguindo as melhores práticas de
                      bem-estar animal.
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </React.Fragment>
  );
};

export default Manejo;
