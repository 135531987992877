import React from "react";
import {
  Grid,
  Link,
  Box,
  Container,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import iucatanLogo from "../../resources/static/iucatan-logo.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const Footer = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#212121",
      },
      secondary: {
        main: grey["50"],
      },
    },
  });

  return (
    <Box
      component={"footer"}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <ThemeProvider theme={theme}>
        <Box
          sx={{ mt: "15px", backgroundColor: theme.palette.primary.main }}
          px={{ xs: 3, sm: 10 }}
          py={{ xs: 5, sm: 10 }}
          color="white"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Box component={"img"} src={iucatanLogo} />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography variant="h6" sx={{ mb: "15px" }}>
                  Comunidade
                </Typography>
                <Box component={"div"} sx={{ mb: "15px" }}>
                  <Link
                    href="https://abcca.com.br/"
                    underline="none"
                    color="inherit"
                  >
                    Associação do Cavalo Árabe
                  </Link>
                </Box>
                <Box sx={{ mb: "15px" }}>
                  <Link
                    href="https://www.fhbr.com.br/modalidade/enduro"
                    underline="none"
                    color="inherit"
                  >
                    Federação Hípica - Enduro
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} alignContent="center">
                <Typography variant="h6" sx={{ mb: "15px" }}>
                  Contatos
                </Typography>

                <Tooltip title="Acesse nosso instagram">
                  <IconButton
                    color="inherit"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/ranchoiucatan/"
                    sx={{
                      mr: 2,
                    }}
                  >
                    <InstagramIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Entre em contato">
                  <IconButton
                    color="inherit"
                    rel="noopener noreferrer"
                    href="https://api.whatsapp.com/send?phone=+55061992093249&text=Estou vindo do site e gostaria de saber mais sobre ! "
                    sx={{
                      mr: 2,
                    }}
                  >
                    <WhatsAppIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Enviar E-Mail">
                  <IconButton
                    color="inherit"
                    rel="noopener noreferrer"
                    href="mailto:braga1358@gmail.com"
                    sx={{
                      mr: 2,
                    }}
                  >
                    <EmailIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Localização">
                  <Link
                    href="https://www.google.com/maps/place/Centro+Equestre+Iucatan/@-15.7524039,-47.6553618,15z/data=!4m5!3m4!1s0x0:0x51e142a2051d4f94!8m2!3d-15.7524039!4d-47.6553618"
                    underline="none"
                    color="inherit"
                  >
                    <LocationOnIcon />
                  </Link>
                </Tooltip>
              </Grid>
            </Grid>
            <Box
              textAlign="center"
              pt={{ xs: 5, sm: 10 }}
              pb={{ xs: 5, sm: 0 }}
            >
              <Link href="https://tbraga.com" underline="none" color="inherit">
                Consultoriadiasti@gmail.com &reg; {new Date().getFullYear()}
              </Link>
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default Footer;
