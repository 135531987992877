import { Box, Tooltip } from "@mui/material";

const CustomComponentImage = ({ Marcos }) => {
  return (
    <Tooltip title="Professor Marquinhos">
      <Box
        sx={{
          width: "100%",
          height: "300px",
          objectFit: "cover",
          objectPosition: "bottom",
        }}
        component="img"
        src={Marcos}
        alt={Marcos}
      />
    </Tooltip>
  );
};

export default CustomComponentImage;
