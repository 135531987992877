import React from "react";
import { Button, Collapse, Container, Grid, Typography } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const Work = ({ openWork, setOpenWork }) => {
  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        xs={12}
      >
        <Grid item xs={12} sx={{ textAlign: "center", ml: "10px", mt: "20px" }}>
          <Button
            sx={{
              backgroundImage:
                "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
              backgroundClip: "text",
              color: "transparent",
            }}
            onClick={() => setOpenWork(!openWork)}
          >
            Atividades Oferecidas {openWork ? <ExpandLess /> : <ExpandMore />}
          </Button>
          <Collapse in={openWork} timeout="auto" unmountOnExit>
            <Grid container spacing={2}>
              <Grid item xs={12} container sm>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xs
                >
                  <Grid item padding="10px">
                    <Typography
                      variant="h5"
                      sx={{
                        background:
                          "-webkit-linear-gradient( rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                        backgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Equitação Básica
                    </Typography>
                    <Button
                      variant="contained"
                      href="/equitacao-basica"
                      sx={{
                        mt: "10px",
                        backgroundImage:
                          "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                      }}
                    >
                      Saber Mais
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xs
                >
                  <Grid item padding="10px">
                    <Typography
                      variant="h5"
                      sx={{
                        background:
                          "-webkit-linear-gradient( rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                        backgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Equitação Avançada
                    </Typography>
                    <Button
                      variant="contained"
                      href="/equitacao-avançada"
                      sx={{
                        mt: "10px",
                        backgroundImage:
                          "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                      }}
                    >
                      Saber Mais
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} container sm>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xs
                >
                  <Grid item padding="10px">
                    <Typography
                      variant="h5"
                      sx={{
                        background:
                          "-webkit-linear-gradient( rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                        backgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Enduro
                    </Typography>
                    <Button
                      variant="contained"
                      href="/treinamento-enduro"
                      sx={{
                        mt: "10px",
                        backgroundImage:
                          "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                      }}
                    >
                      Saber Mais
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xs
                >
                  <Grid item padding="10px">
                    <Typography
                      variant="h5"
                      sx={{
                        background:
                          "-webkit-linear-gradient( rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                        backgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Equoterapia
                    </Typography>
                    <Button
                      variant="contained"
                      href="/equitacao-equoterapia"
                      sx={{
                        mt: "10px",
                        backgroundImage:
                          "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                      }}
                    >
                      Saber Mais
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} container sm>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xs
                >
                  <Grid item padding="10px">
                    <Typography
                      variant="h5"
                      sx={{
                        background:
                          "-webkit-linear-gradient( rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                        backgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Manejo
                    </Typography>
                    <Button
                      variant="contained"
                      href="/manejo"
                      sx={{
                        mt: "10px",
                        backgroundImage:
                          "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                      }}
                    >
                      Saber Mais
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  xs
                >
                  <Grid item padding="10px">
                    <Typography
                      variant="h5"
                      sx={{
                        background:
                          "-webkit-linear-gradient( rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                        backgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Equipamentos
                    </Typography>
                    <Button
                      variant="contained"
                      href="/equipamentos"
                      sx={{
                        mt: "10px",
                        backgroundImage:
                          "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                      }}
                    >
                      Saber Mais
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Work;

// </Grid>
