import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React from "react";
import Footer from "../../footer/Footer";
const Eventos = () => {
  return (
    <React.Fragment>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box component={"div"} sx={{ textAlign: "center", ml: "10px" }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                mt: "30px",
                backgroundImage:
                  "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Eventos
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Box
            component={"div"}
            sx={{ textAlign: "center", ml: "10px", mt: "30px", width: "75%" }}
          >
            <Paper about="descricao equitação básica" elevation={2}>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Cavalgada
                      <List>
                        <ListItem>
                          <ListItemText>
                            <Typography variant="h6">
                              Cavalgada : São eventos equestres tradicionais
                              realizados por grupos de cavaleiros montados a
                              cavalo. Eles são geralmente organizados como um
                              passeio a cavalo por trilhas ou estradas rurais,
                              em uma distância determinada, e podem durar dias
                              ou semanas. O objetivo é celebrar a relação entre
                              o cavaleiro e o cavalo, bem como a natureza e a
                              vida rural. Além disso, cavalgadas podem ter
                              elementos culturais, como música e danças, e
                              servem como uma oportunidade para os participantes
                              se conectarem com outros amantes de cavalos. Em
                              algumas regiões, cavalgadas são eventos
                              importantes na vida comunitária e são realizadas
                              anualmente.
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      </List>
                      <List>
                        <ListItem>
                          <ListItemText>
                            <Typography variant="h6">
                              Cavalgada da Lua: Cavalgada da Lua é uma tradição
                              equestre realizada à noite, durante a lua cheia. O
                              evento consiste em um grupo de cavaleiros montados
                              em cavalos, percorrendo uma trilha iluminada pela
                              lua. A cavalgada da lua é uma oportunidade para os
                              participantes experimentarem uma aventura noturna
                              e desfrutarem da beleza da paisagem iluminada pela
                              lua. Além disso, a cavalgada da lua é uma
                              celebração da conexão entre o cavaleiro, o cavalo
                              e a natureza, e pode incluir elementos culturais,
                              como música ao vivo e acampamentos noturnos. É uma
                              experiência única e emocionante para aqueles que
                              amam cavalos e vida ao ar livre.
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Typography>
                    <Box component={"div"} sx={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        sx={{
                          mt: "10px",
                          backgroundImage:
                            "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                        }}
                        href="https://api.whatsapp.com/send?phone=+55061992093249&text=Estou vindo do site e gostaria de saber mais sobre as cavalgadas! "
                      >
                        Agendar
                      </Button>
                    </Box>
                  </ListItemText>
                </ListItem>
              </List>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Aniversários
                      <List>
                        <ListItem>
                          <ListItemText>
                            <Typography variant="h6">
                              Uma festa de aniversário com passeio a cavalo é
                              uma forma divertida e inusitada de celebrar um
                              aniversário. A festa começa com uma cavalgada
                              guiada por trilhas, durante a qual os convidados
                              podem desfrutar da paisagem a cavalo. O passeio é
                              seguido por uma celebração com comida, bebidas e
                              música, onde todos podem se reunir para celebrar o
                              aniversário do anfitrião. Este tipo de festa é
                              ideal para amantes de cavalos e aventura, e pode
                              ser realizada em fazendas ou parques com
                              instalações equestres. Além disso, o passeio a
                              cavalo pode ser personalizado para atender aos
                              gostos e habilidades dos convidados, tornando a
                              festa ainda mais divertida e inesquecível.
                            </Typography>
                            <Box component={"div"} sx={{ textAlign: "center" }}>
                              <Button
                                variant="contained"
                                sx={{
                                  mt: "10px",
                                  backgroundImage:
                                    "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                                }}
                                href="https://api.whatsapp.com/send?phone=+55061992093249&text=Estou vindo do site e gostaria de saber mais sobre os aniversários ! "
                              >
                                Agendar
                              </Button>
                            </Box>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6">
                      <ArrowForwardIcon sx={{ mr: "10px" }} /> Palestras
                      <List>
                        <ListItem>
                          <ListItemText>
                            <Typography variant="h6">
                              As palestras com tema de equitação são uma
                              oportunidade para os amantes de cavalos e da
                              equitação aprender mais sobre essa atividade.
                              Essas palestras podem incluir tópicos como
                              história da equitação, técnicas de treinamento de
                              cavalos, cuidados com o cavalo e equipamentos,
                              segurança durante a equitação, entre outros. Elas
                              são geralmente apresentadas por profissionais
                              experientes na área, como treinadores, médicos
                              veterinários, e outros especialistas. Além de
                              fornecer informações valiosas sobre equitação, as
                              palestras também são uma oportunidade para os
                              participantes se conectarem com outros amantes de
                              cavalos e trocarem ideias e experiências. Essas
                              palestras podem ser realizadas em clubes
                              equestres, escolas de equitação, feiras
                              agropecuárias e outros locais relacionados a
                              cavalos. A participação em palestras sobre
                              equitação pode ajudar os amantes desse esporte a
                              melhorar suas habilidades e conhecimentos,
                              tornando-se mais confiantes e seguros na sela.
                              Essas palestras também são uma oportunidade para
                              aprender sobre novos equipamentos e técnicas, e
                              para se manterem atualizados sobre as últimas
                              tendências e desenvolvimentos na área.
                            </Typography>
                            <Box component={"div"} sx={{ textAlign: "center" }}>
                              <Button
                                variant="contained"
                                sx={{
                                  mt: "10px",
                                  backgroundImage:
                                    "linear-gradient(45deg, rgba(87, 9, 9, 1), rgba(252, 2, 20, 0.8))",
                                }}
                                href="https://api.whatsapp.com/send?phone=+55061992093249&text=Estou vindo do site e gostaria de saber mais sobre as palestras ! "
                              >
                                Agendar
                              </Button>
                            </Box>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </React.Fragment>
  );
};

export default Eventos;
